<h1>{{'Users'|translate}}</h1>
<span *ngxPermissionsOnly="['can_create_users']">
  <paper-fab  mat-raised-button icon="add" class="action" color="accent" routerLink="/manage-users/new-user"></paper-fab>
</span>
<h2>{{'Active Users'|translate}}</h2>
<mat-table [dataSource]="activeUsers" class="mat-elevation-z8">
  <ng-container matColumnDef="username">
    <mat-header-cell *matHeaderCellDef> {{'Username'|translate}} </mat-header-cell>
    <mat-cell *matCellDef="let user">
      <span class="tangy-foreground-secondary">{{user.username}}</span>
    </mat-cell>
  </ng-container>
  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef> {{'Email'|translate}} </mat-header-cell>
    <mat-cell *matCellDef="let user"> {{user.email}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef> {{'Actions'|translate}} </mat-header-cell>
    <mat-cell *matCellDef="let user" >
      <a
        class="tangy-foreground-primary"
        *ngxPermissionsOnly="['can_manage_users_site_wide_permissions']"
        [routerLink]="['/manage-users/sitewide-permissions',  user.username]"
        title="{{'Security'|translate}}"
      >
        <i class="material-icons mat-24 tangy-location-list-icon">security</i>
      </a>
      <a
        class="tangy-foreground-primary"
        *ngxPermissionsOnly="['can_edit_users']"
        [routerLink]="['/manage-users/users/edit/',  user.username]"
        title="{{'Edit'|translate}}"
      >
        <i class="material-icons mat-24 tangy-location-list-icon">edit</i>
      </a>
      <span
        class="tangy-foreground-primary"
        *ngxPermissionsOnly="['can_edit_users']"
        (click)="deleteUser(user.username)"
        title="{{'Delete'|translate}}"
      >
        <i class="material-icons mat-24 tangy-location-list-icon">delete</i>
      </span>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="usersDisplayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: usersDisplayedColumns;"></mat-row>
</mat-table>
<h2>{{'Archived Users'|translate}}</h2>
<mat-table [dataSource]="archivedUsers" class="mat-elevation-z8">
  <ng-container matColumnDef="username">
    <mat-header-cell *matHeaderCellDef> {{'Username'|translate}} </mat-header-cell>
    <mat-cell *matCellDef="let user"> <span class="tangy-foreground-secondary">{{user.username}}</span> </mat-cell>
  </ng-container>
  <ng-container matColumnDef="email">
    <mat-header-cell *matHeaderCellDef> {{'Email'|translate}} </mat-header-cell>
    <mat-cell *matCellDef="let user"> {{user.email}} </mat-cell>
  </ng-container>
  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef> {{'Actions'|translate}} </mat-header-cell>
    <mat-cell *matCellDef="let user">
      <a class="tangy-foreground-primary" [routerLink]="['/manage-users/users/edit/',  user.username]">
        <i class="material-icons mat-18 tangy-location-list-icon">edit</i>
      </a>
      <span class="tangy-foreground-primary" (click)="restoreUser(user.username)">
        <i class="material-icons mat-18 tangy-location-list-icon">restore_from_trash</i>
      </span>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="usersDisplayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: usersDisplayedColumns;"></mat-row>
</mat-table>