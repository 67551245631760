<app-breadcrumb [title]="title" [breadcrumbs]="breadcrumbs"></app-breadcrumb>

<ng-container *ngIf="ready">

  <mat-card appearance="outlined" *appHasAPermission="let i;group:groupId; permission:'can_access_dashboard'" class="is-link mat-elevation-z3" routerLink="../dashboard">
    <mat-card-header>
        <div mat-card-avatar><i class="material-icons tangy-foreground-secondary group-avatar">dashboard</i>
        </div>
        <mat-card-title>
            <a>Dashboard</a>
        </mat-card-title>
        <mat-card-subtitle>Dashboard with custom reports.</mat-card-subtitle>
    </mat-card-header>
  </mat-card>
  
  <span *ngIf="config.enabledModules.includes('case')">
    <mat-card appearance="outlined" *appHasAPermission="let i;group:groupId; permission:'can_access_cases'"  class="mat-elevation-z3">
      <mat-card-header>
          <div mat-card-avatar>
            <mwc-icon class="tangy-foreground-secondary">folder</mwc-icon>
          </div>
          <mat-card-title>
              <a>{{'Case Management'|translate}}</a>
          </mat-card-title>
          <mat-card-subtitle>{{'Review uploaded cases and related Issues.'|translate}}</mat-card-subtitle>
      </mat-card-header>
      <mat-card-actions>
      <button mat-stroked-button color="accent" routerLink="cases"><mwc-icon class="tangy-foreground-secondary">folder</mwc-icon> {{'Cases'|translate}}</button>
      <button mat-stroked-button color="accent" *appHasAPermission="let i;group:groupId; permission:'can_access_issues'" routerLink="issues"><mwc-icon>bug_report</mwc-icon> {{'Issues'|translate}}</button>
    </mat-card-actions>
    </mat-card>
  </span>

  <mat-card appearance="outlined" *appHasAPermission="let i;group:groupId; permission:'can_access_download_csv'" class="mat-elevation-z3">
    <mat-card-header>
      <div mat-card-avatar>
        <mwc-icon class="tangy-foreground-secondary">table_chart</mwc-icon>
      </div>
      <mat-card-title>
        <a>{{'Download Data/Exports'|translate}}</a>
      </mat-card-title>
      <mat-card-subtitle>{{"Request multiple Spreadsheets at once, view Spreadsheets you've requested in the past, and manage Spreadsheet Templates which you can use to control the order of columns and which variables show up on Spreadsheets." |translate}}</mat-card-subtitle>
    </mat-card-header>
    <mat-card-actions>
      <button mat-stroked-button color="accent" routerLink="csv-data-sets/new"><mwc-icon>add</mwc-icon> {{'Request Spreadsheets'|translate}}</button>
      <button mat-stroked-button color="accent" routerLink="csv-data-sets"><mwc-icon>list</mwc-icon> {{'Spreadsheet Requests'|translate}}</button>
      <button mat-stroked-button color="accent" routerLink="csv-templates"><mwc-icon>tune</mwc-icon> {{'Manage Spreadsheet Templates'|translate}}</button>
    </mat-card-actions>
  </mat-card>

  <span *ngIf="config.enabledModules.includes('sync-protocol-2')">
    <mat-card appearance="outlined" *appHasAPermission="let i;group:groupId; permission:'can_access_database_conflicts'" class="is-link mat-elevation-z3" routerLink="database-conflicts">
      <mat-card-header>
          <div mat-card-avatar>
            <mwc-icon class="tangy-foreground-secondary">receipt</mwc-icon>
          </div>
          <mat-card-title>
            <a>{{'Database Conflicts'|translate}}</a>
          </mat-card-title>
          <mat-card-subtitle>{{'Review Database Conflicts. Database Conflicts occur when the same Form or Case is modified in two different Devices between syncs. Use Active Conflicts tool to resolve conflicts and Archived Conflicts to see conflicts you have resolved in the past.'|translate}}</mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </span>
  
  <mat-card appearance="outlined" *appHasAPermission="let i;group:groupId; permission:'can_access_uploads'" class="is-link mat-elevation-z3" routerLink="uploads" [hidden]="!showUploads">
    <mat-card-header>
      <div mat-card-avatar>
        <mwc-icon class="tangy-foreground-secondary">cloud_upload</mwc-icon>
      </div>
      <mat-card-title>
        <a>{{'Uploads'|translate}}</a>
      </mat-card-title>
      <mat-card-subtitle>{{'View uploaded form responses from devices.'|translate}}</mat-card-subtitle>
    </mat-card-header>
  </mat-card>

</ng-container>