<div class="mat-color--amber color-header clearfix">
    <div class="color-explanation mat-color-text--white">
        <h4>Profile</h4>
    </div>
</div>
<div class="mat-grid mat-grid--no-spacing">
    <div class="mat-cell mat-cell--3-col mat-cell--12-col-tablet mat-cell--12-col-phone mat-color--grey-100">
        <div class="left-info-box">
            <!--<h5><i class="material-icons">format_align_left</i> Help</h5>-->
        </div>
    </div>
    <div class="mat-cell mat-cell--9-col mat-cell--12-col-tablet mat-cell--12-col-phone r-content">
        <div class="body-content">
            <div *ngIf="profile">
                <mat-card appearance="outlined" mat-shadow="4" *ngIf="isPaid">
                    <mat-card-title [class.payment-highlight]="isPaid">
                        <h2 mat-card-title-text>Welcome!</h2>
                    </mat-card-title>
                    <mat-card-subtitle>
                        <div>
                            {{isPaidMessage}}
                        </div>
                    </mat-card-subtitle>
                </mat-card>
                <mat-card appearance="outlined" mat-shadow="4">
                    <mat-card-title>
                        <h2 mat-card-title-text>Member</h2>
                    </mat-card-title>
                    <mat-card-subtitle>
                        <div>
                            {{profile.firstName}} {{profile.firstName}}<br/>
                            Email: {{profile.email}}<br/>
                            Organization Name: {{profile.organizationName}}<br/>
                            Organization Type: {{profile.organizationType}}<br/>
                            Title: {{profile.title}}<br/>
                            Location: {{profile.state}}, {{profile.location}} <button (click)="edit()">Edit</button><br/>
                            Email Opt Out: {{profile.emailOptOut}}
                        </div>
                    </mat-card-subtitle>
                </mat-card>

                <mat-card appearance="outlined" mat-shadow="4">
                    <mat-card-title>
                        <h2 mat-card-title-text>Site</h2>
                    </mat-card-title>
                    <mat-card-subtitle>
                    <div>
                         URL: {{profile.url}}<br/>
                         Username: {{profile.username}}<br/>
                    </div>
                    </mat-card-subtitle>
                </mat-card>
                <mat-card appearance="outlined" mat-shadow="4">
                    <mat-card-title>
                        <h2 mat-card-title-text>Plan</h2>
                    </mat-card-title>
                    <mat-card-subtitle>
                        <div [class.payment-highlight]="isPaid">
                            Plan: {{profile.officialPlan}}<br/>
                            Plan Description: {{profile.planDescription}}<br/>
                            Plan Expiry: {{profile.planExpiry | date:'medium'}}<br/>
                            Last Paid Amount: ${{profile.officialLastPaidAmount}} on {{profile.officialLastPaidDate | date:'medium'}}<br/>
                            Payment Reference: {{profile.pnref}}<br/>
                            Initial Registration: {{profile.initialRegistration | date:'medium'}}
                       </div>
                    </mat-card-subtitle>
                </mat-card>
            </div>
        </div>
    </div>
</div>

