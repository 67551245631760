<app-breadcrumb [title]="title" [breadcrumbs]="breadcrumbs"></app-breadcrumb>

<div class="full-width">
  <div class="container">
    <div class="configure-instructions">{{ "Configure the date range (optional), data set description, Personal Identifiable Information (PII) exclusion, and which forms to generate Spreadsheets for." | translate }}</div>
  </div>
  <form class="form">
    <div class="container">
      <div id="year-and-month">
        <mat-form-field appearance="fill" color="primary">
          <mat-label>{{ "Month" | translate }}</mat-label>
          <mat-select
            name="selectedMonth"
            class="month"
            [(ngModel)]="selectedMonth"
          >
            <mat-option value="*" selected="selected">All months</mat-option>
            <mat-option *ngFor="let month of months" value="{{ month }}">{{
              month
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" color="primary">
          <mat-label>{{ "Year" | translate }}</mat-label>
          <mat-select name="selectedYear" class="year" [(ngModel)]="selectedYear">
            <mat-option value="*" selected="selected">All years</mat-option>
            <mat-option *ngFor="let year of years" value="{{ year }}">{{
              year
            }}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-checkbox name="excludePII" [(ngModel)]="excludePII"
          >{{ "Exclude PII?" | translate }}
        </mat-checkbox>
      </div>
      <div
        id="description"
      >
        <mat-form-field>
          <input
              name="descriptionInput"
              [(ngModel)]="description"
              #groupNameInput="ngModel"
              matInput
              placeholder="{{'Description'|translate}}"
          >
        </mat-form-field>
      </div>
      <div class="select-instructions">{{ "Click the checkbox to the left of the 'Form title' column to select all forms. If there is a Spreadsheet Template available for a form, it will be displayed in the form's Spreadsheet Template dropdown." | translate }}</div>
    </div>
    <h2 class="tangy-foreground-secondary">Active Forms</h2>
    <table mat-table class="form-list" [dataSource]="activeForms" >
      <!-- toggle Column -->
      <ng-container matColumnDef="toggle">
        <th mat-header-cell *matHeaderCellDef><mat-checkbox name="toggle-select-all-forms" (change)="toggleSelectAllForms($event, 'active')"></mat-checkbox></th>
        <td mat-cell *matCellDef="let form"> <mat-checkbox [checked]="allActiveFormsSelected" (change)="onFormCheckBoxChange(form.id, $event)"></mat-checkbox> </td>
      </ng-container>
      <!-- Title Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef> Form Title </th>
        <td mat-cell *matCellDef="let form"> {{form.title}} </td>
      </ng-container>
      <!-- Template Column -->
      <ng-container matColumnDef="template">
        <th mat-header-cell *matHeaderCellDef> Spreadsheet Template </th>
        <td mat-cell *matCellDef="let form">
          <mat-form-field class="csv-template-selection" appearance="fill" color="primary">
            <mat-select name="template" [(ngModel)]="templateSelections[form.id]">
              <mat-option value="" selected>{{ "All data" | translate }}</mat-option>
              <mat-option *ngFor="let csvTemplate of form.csvTemplates" [value]="csvTemplate._id">{{csvTemplate.title}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>

    <h2 class="tangy-foreground-secondary">Archived Forms</h2>
    <table mat-table class="form-list" [dataSource]="archivedForms">
      <!-- toggle Column -->
      <ng-container matColumnDef="toggle">
        <th mat-header-cell *matHeaderCellDef><mat-checkbox name="toggle-select-all-forms" (change)="toggleSelectAllForms($event, 'archived')"></mat-checkbox></th>
        <td mat-cell *matCellDef="let form"> <mat-checkbox [checked]="allArchivedFormsSelected" (change)="onFormCheckBoxChange(form.id, $event)"></mat-checkbox> </td>
      </ng-container>
      <!-- Title Column -->
      <ng-container matColumnDef="title">
        <th mat-header-cell *matHeaderCellDef> Form Title </th>
        <td mat-cell *matCellDef="let form"> {{form.title}} </td>
      </ng-container>
      <!-- Template Column -->
      <ng-container matColumnDef="template">
        <th mat-header-cell *matHeaderCellDef> Spreadsheet Template </th>
        <td mat-cell *matCellDef="let form">
          <mat-form-field class="csv-template-selection" appearance="fill" color="primary">
            <mat-select name="template" [(ngModel)]="templateSelections[form.id]">
              <mat-option value="" selected>{{ "All data" | translate }}</mat-option>
              <mat-option *ngFor="let csvTemplate of form.csvTemplates" [value]="csvTemplate._id">{{csvTemplate.title}}</mat-option>
            </mat-select>
          </mat-form-field>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <div id="submit-container">
      <button mat-raised-button color="warn" (click)="process()" [disabled]="selectedForms.length<1">{{'Submit Request'|translate}}</button>
    </div>
  </form>
</div>
