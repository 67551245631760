<app-breadcrumb [title]="title" [breadcrumbs]="breadcrumbs"></app-breadcrumb>
<h2 class="tangy-foreground-secondary">{{'Active Forms'|translate}}</h2>
<table mat-table [dataSource]="activeForms" class="mat-elevation-z8">
  <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
    <th mat-header-cell *matHeaderCellDef>
      {{column.header}}
    </th>
    <td mat-cell *matCellDef="let row" >
      <ng-template #falseTemp>
        <span>
          <tangy-checkbox (click)="toggleTwoWaySyncOnForm(groupId, row.id)"
                          value="{{row.couchdbSyncSettings.pull ? 'on'  : ''}}"></tangy-checkbox>
        </span>
      </ng-template>
      <span *ngIf="column.columnDef !== 'pull'; else falseTemp">{{column.cell(row)}}</span>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<h2 class="tangy-foreground-secondary">{{'Archived Forms'|translate}}</h2>
<div *ngIf="archivedForms && archivedForms.length > 0">
<table mat-table [dataSource]="archivedForms" class="mat-elevation-z8">
  <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
    <th mat-header-cell *matHeaderCellDef>
      {{column.header}}
    </th>
    <td mat-cell *matCellDef="let row" >
      <ng-template #falseTemp>
        <span>
          <tangy-checkbox (click)="toggleTwoWaySyncOnForm(groupId, row.id)"
                          value="{{row.couchdbSyncSettings.pull ? 'on'  : ''}}"></tangy-checkbox>
        </span>
      </ng-template>
      <span *ngIf="column.columnDef !== 'pull'; else falseTemp">{{column.cell(row)}}</span>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
</div>
<div class="indent" *ngIf="! archivedForms || archivedForms.length === 0">
  <p>No archived forms</p>
</div>
