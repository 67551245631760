<app-breadcrumb [title]="title" [breadcrumbs]="breadcrumbs"></app-breadcrumb>
<div class="indent">
  <h2 class="tangy-foreground-secondary">Instructions</h2>
  <p class="indent">The Security feature lists users and assigns users to specific roles. You must first create Users by clicking the "Users" link on the left navigation menu.</p>
</div>

<mat-tab-group>
  <mat-tab label="{{'Users'|translate}}">
      <app-list-users></app-list-users>
  </mat-tab>
  <mat-tab label="{{'Roles'|translate}}" *appHasAPermission="let i;group:groupId; permission:'can_manage_group_roles'">
      <app-manage-group-roles-permissions></app-manage-group-roles-permissions>
  </mat-tab>
</mat-tab-group>