<!--<div class="media-list">-->
<h1 mat-dialog-title>Media Listing</h1>
<div mat-dialog-content>
  <file-list-http #list></file-list-http>
</div>
<div mat-dialog-actions>
<!--  <button mat-button [mat-dialog-close]="data" (click)= "selectMedia()" cdkFocusInitial>Add</button>-->
<!--  <button mat-raised-button [mat-dialog-close] = "selectMedia()" cdkFocusInitial>Add</button>-->
  <button mat-raised-button (click)="selectMedia()" cdkFocusInitial>Add media to input</button>
  <button mat-raised-button (click)="onNoClick()">No Thanks</button>
<!--  <button mat-raised-button (click)= "selectMedia()" cdkFocusInitial>Add media to input</button>-->
</div>
<!--</div>-->